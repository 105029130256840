import React from 'react';
import Image from 'components/common/Image';
import LayoutPanel from 'components/layout/layoutPanel';

function CanstarInfo (props) {

  return (
    <LayoutPanel background={props.panelBackground}
                 padding={props.panelPadding}
                 {...props}>

      <div className="container">
        <div className="row mer-panel">

          <div className="mer-panel__images col-5 col-lg-2 col-sm-3 mb-0 mr-lg-5">
              {/* { props.ngAvailable &&
                    <>
                        <Image className="mer-panel__image"
                             src="/canstar-ng.png"
                             alt="Canstar natural gas"
                        />
                    </>
              }
              { props.lpgAvailable &&
                    <>
                        <Image className="mer-panel__image"
                             src="/canstar-lpg.png"
                             alt="Canstar lpg"
                        />
                    </>
              } */}
              	<Image className="mer-panel__image"
					   src="/icons/flowmoji/flowmoji-agent.svg"
					   alt="Customer service flowmoji" />
          </div>
          <div className="content col-10 col-xl-5 col-md-6 col-sm-7 offset-sm-1 offset-lg-0">
                {/* { props.ngAvailable &&
                    <>
                        <h3>Award-winning service</h3>
                        <p>We’re all about making gas easy and thrilled our customers agree – giving us a five-star rating for overall satisfaction five years running in Canstar Blue’s Customer Satisfaction Survey.</p>
                    </>
                }
                { props.lpgAvailable &&
                    <>
                        <h3>Best in-class customer service</h3>
                        <p>We aim to make gas easy for all our customers and we’re thrilled they agree – handing us a five star rating for overall satisfaction with the award of Canstar Blue’s Most Satisfied Customers – LPG National 2020.</p>
                    </>
                } */}
				<h3>Service with a smile</h3>
				<p>We’re in WA, and we’re here for you. Providing top-notch service to all our customers across WA and the NT. Kleenheat makes gas easy, so life can just flow.</p>
          </div>
        </div>
      </div>
    </LayoutPanel>
  )
}

export default CanstarInfo;