import React, { useEffect, useState } from 'react';
import { isAfter } from 'date-fns';

import SEO from 'components/Seo';
import { LinkModal } from 'kh-common-components';

import LayoutMain from 'components/layout/layoutMain';
import LayoutPanel from 'components/layout/layoutPanel';

import CanstarInfo from "components/cards/CanstarInfo";
import LocalMessaging from "components/cards/LocalMessaging";

import Link from 'components/common/Link';
import Faq from 'components/common/Faq';
import Panel from "components/common/Panel";

import faqDataPreOffer from 'constants/components/accordion-list/rac50-pre-offer-faq';
import faqDataOffer from 'constants/components/accordion-list/rac50-offer-faq';
import panelData from 'constants/rac50/panel';
import { modalContents } from 'constants/modal-content';

import 'styles/pages/rac50.css';

// asset images
// https://www.gatsbyjs.com/docs/how-to/images-and-media/importing-assets-into-files/
import imgRacKHLogo from 'images/rac50/kleenheat-rac-logo.png';
import heroBanner from 'images/rac50/hero-tea.png';

function Rac50Page() {

	const [showUpdatedCopy, setShowUpdatedCopy] = useState(false);

	useEffect(() => {
        
        const isNonPrd = process.env.GATSBY_ACTIVE_ENV !== 'prd';
        const currentDate = new Date();

        const triggerSwitchDateTime = isNonPrd ? new Date(2022, 4, 6, 17, 52) : new Date(2022, 4, 17); // 17 May 2022
        const triggerSwitchCopy = isAfter(currentDate, triggerSwitchDateTime);

		if (triggerSwitchCopy) {
			setShowUpdatedCopy(true);
		}


        // for testing on non prod
        // ?update=false
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const update = urlParams.get('update');

        if (update && isNonPrd) {
			setShowUpdatedCopy(false);
        }

    }, []);

    


	return (
		<>
            <SEO title="Exclusive Kleenheat offer for RAC Members | Kleenheat" 
            	 description="RAC members can enjoy bigger savings on their natural gas with Kleenheat."
                 meta={[{name:"robots", content:'noindex'}]}
        	/>
            <LayoutMain>

            	{/* hero banner */}
            	<LayoutPanel background="mer-bg--primary-cyan"                 
			                 padding="mer-pb-ju"
			                 theme="mer-theme--cyan">
			        <div className="container">
			            <div className="row mer-panel justify-content-center align-items-end">
		                    <div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg">

		                    	<div className="logo-rac50">
						          <img src={imgRacKHLogo} 
			                           alt="Kleenheat" />
						        </div>

		                        <div className="content">

                                    <h1 className="mer-typography--headline2">Cosy up with gas savings this winter.</h1>
                                    <p>RAC members can enjoy <strong>38% off their natural gas usage charges for 12 months*.</strong></p>

                                    {/* offer */}
                                    <p className={showUpdatedCopy ? 'd-block' : 'd-none'}><strong>PLUS,</strong> for a limited time, receive a $50 credit on your first natural gas bill^ when you switch to Kleenheat before 31 August 2023.</p>

						          	<div className="mer-panel__actions mer-button-group">
                                        {/* pre-offer */}
                                        <Link text="Switch now"
                                              link="/sign-up/promo/RACDISC/537"
                                              linkClass={showUpdatedCopy ? 'd-none' : 'd-block mer-button mer-button--primary'} />

                                        {/* offer */}
		                                <Link text="Switch now"
                                              link="/sign-up/promo/RACBONUS50NG/537"
                                              linkClass={showUpdatedCopy ? 'd-block mer-button mer-button--primary' : 'd-none'} />

                                        <Link text="Learn more"
                                              link="#local"
                                              linkClass="mer-button mer-button--secondary" />
		                            </div>
		                        </div>
		                    </div>

		                    <div className="mer-panel__images col-10 col-sm-8 col-lg-5 offset-lg-1">
						        <div className="mer-panel__image">
                                    <img src={heroBanner} 
                                         className="mer-panel__image hero-banner-rac" 
                                         alt="Hero bottles" />
						        </div>
                                
                                <div className="pt-3">
                                    {/* pre-offer */}
                                    <small><LinkModal
                                    modalContents={modalContents}
                                    useModal="true"
                                    title="RAC terms & conditions"
                                    linkClass={showUpdatedCopy ? 'd-none' : 'd-block'}
                                    displayContent="*Terms and conditions apply"
                                    modalTitle="RAC terms & conditions"
                                    modalContentName="rac-ng-terms"
                                    okayButtonLabel="Okay"
                                    /></small>

                                    {/* offer */}
                                    <small><LinkModal
                                    modalContents={modalContents}
                                    useModal="true"
                                    title="RAC terms & conditions"
                                    linkClass={showUpdatedCopy ? '' : 'd-none'}
                                    displayContent="*Terms and conditions apply"
                                    modalTitle="RAC terms & conditions"
                                    modalContentName="rac50-ng-terms"
                                    okayButtonLabel="Okay"
                                    /></small>
                                </div>
					        </div>
			            </div>
					</div>
			    </LayoutPanel>

		    	{/* usp */}
		    	<LayoutPanel background="mer-bg--ui-light"
			                 padding="mer-py-lg"
			                 border="mer-border--bottom"
			                 id="local">
			        <div className="container">

			        	<div className="row align-items-center justify-content-center">
	                		<div className="col-10 col-sm-8 mer-text--size-lg">
				                <div className="content text-center">
				                	<h2 className="mer-color--primary-cyan mer-typography--headline3">WA's local energy provider</h2>
				                </div>
				            </div>
	                	</div>

                        {/* 1 */}
                        <LocalMessaging addressState="WA"
                                        panelBackground="mer-bg--ui-light"
                                        panelPadding="mer-py-de" />

                        {/* 2 */}
                        <Panel data={panelData} 
                            id="panel-2"
                        />

                        {/* 3 */}
                        <Panel data={panelData} 
                            id="panel-3"
                        />

                        {/* 4 */}
                        <Panel data={panelData} 
                            id="panel-4"
                        />

                        <CanstarInfo panelBackground="mer-bg--ui-light"
	            			         panelPadding="mer-pt-de mer-pb-ju"
	            			         ngAvailable={true} />
			        </div>
		        </LayoutPanel>
                

                {/* pre-offer */}
                <Faq panelBackground={showUpdatedCopy ? 'd-none' : 'd-block mer-bg--gs-grey-lighter'}
		  			 panelPadding="mer-py-lg"
		  			 id="faq"
		  			 data={faqDataPreOffer} />

                {/* offer */}
                <Faq panelBackground={showUpdatedCopy ? 'd-block mer-bg--gs-grey-lighter' : 'd-none'}
		  			 panelPadding="mer-py-lg"
		  			 id="faq"
		  			 data={faqDataOffer} />

		    </LayoutMain>
		</>
	)
}

export default Rac50Page;